import React,{useState,useEffect} from "react";
import PasswordStrengthBar from 'react-password-strength-bar';

import {TenantFrontendApi_ChangeUserPassword} from "./TenantFrontendApi";
import { Translate } from "./Translator"
import Header from "./Header"
import MainMenu from "./MainMenu";
import Footer from "./Footer"
import BlueBar from "./BlueBar";

import './Tenant.css';
import './EditUser.css';

function ResetPassword(){

    const [mRepaint,SetRepaint]=useState(false)
    const [mUserGuid,SetUserGuid]=useState()
    const [mUserPassword,SetUserPassword] = useState("")
    const [mConfirmPassword,SetConfirmPassword] = useState("")
    const [mChangePasswordResult,SetChangePasswordResult] = useState("")
    const [mChangePasswordSuccessfully,SetChangePasswordSuccessfully] = useState(false)
    const [mPasswordVisible, SetPasswordVisible] = useState(false)
    
    const [mTenantGuid,SetTenantGuid]=useState()
    const [mToken, SetToken] = useState("")

    useEffect(() => {

        //Get the parameters from the URL
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        const ciParams = new URLSearchParams();
        for (const [name, value] of params) { ciParams.append(name.toLowerCase(), value); }
        const Token = ciParams.get('token')
        sessionStorage.setItem("token", Token) 
        SetToken(Token)

        SetUserGuid("")
        SetTenantGuid("")
        
    }, []);

    async function ChangeUserPassword(){
        try
        {
            console.log("Changing user password")
            const ChangeUserPasswordResponse = await TenantFrontendApi_ChangeUserPassword(mToken,mUserGuid,mUserPassword,mTenantGuid)
            console.log(JSON.stringify(ChangeUserPasswordResponse))
            SetChangePasswordResult("The user password is updated successfully")
            SetChangePasswordSuccessfully(true)
        }

        catch (ex)
        {
            var error = ex;
            try { error = ex.response.data.Exception; } catch {}  
            console.log("Error: " + error);
            SetChangePasswordResult("The user password is not updated correctlly")
            SetChangePasswordSuccessfully(false)
        }
    }

    /*function handleSaveUserSettings(){
        SetPressSaveUserSettings(true)
        return(
            UpdateUser()
        )
       
    }*/

    function handleChangePassword(){

        if(mUserPassword && mConfirmPassword)

        {
            if(mUserPassword === mConfirmPassword)
            {
                return(ChangeUserPassword())
            }

            else
            {
                //the passwords are not same 
                SetChangePasswordSuccessfully(false)
                SetChangePasswordResult("The passwords are not same")

            }  
        }
        else
        {
            SetChangePasswordSuccessfully(false)
            SetChangePasswordResult("Both fields should be fill it")  
            
        }
    }        

    function languageChanged(){
        SetRepaint(!mRepaint)
    }

    return(      
            <span>

                <Header onLanguageChanged={() => languageChanged()}/>
                
                <MainMenu MainMenuNumber={5} />

                <table width="1200" cellPadding={0} cellSpacing={0} align="center">
                <tbody>

                    <tr className="pagetop3">
                        <td height="75" valign="bottom">
                            <table width="100%" cellPadding={0} cellSpacing={0} className="down10px">
                                <tbody>
                                    
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
                </table>

                <table width="1200" cellPadding={0} cellSpacing={0} align="center" >
                    <tbody>                

                        <tr className="pagetop4">
                            <td colSpan={4} height="75" valign="left" className="activetenants width30">
                                {Translate("User settings")}
                            </td>
                        </tr>

                        <tr className="pagetop4">
                            <td colSpan={4} className="horizontalLine width30" >
                                <BlueBar/>
                            </td>
                        </tr>

                        <tr height="50"/>                
                    </tbody>
            </table>

            <table className="usermainsettingtable" width="1200" cellPadding={0} cellSpacing={0} align="center">
            <tbody>

                <tr height="20" ></tr>
                   
                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizelarge">{Translate("Change password")}</td>
                    <td></td>
                </tr>
                
                <tr height="20"/>
                
                <tr>
                    <td width="40"></td>
                    <td>
                        {Translate("New password must comply with the following")}
                    </td>
                </tr>
                
                <tr height="8"/>
                   
                <tr>
                    <td width="40"></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="8"/>
                                    <td className="dot"></td>
                                    <td className="fontsizesmall paddingleft">{Translate("Must contain between 8 and 50 characters")}</td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr height="8"/>
                
                <tr>
                    <td width="40"></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="8"/>
                                    <td className="dot"></td>
                                    <td className="fontsizesmall paddingleft">
                                        {Translate("Must contain at least 1 letter")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr height="8"/>

                <tr>
                    <td width="40"></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="8"/>
                                    <td className="dot"></td>
                                    <td className="fontsizesmall paddingleft">
                                        {Translate("Must contain at least 1 digit")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr height="8"/>
                   
                <tr>
                    <td width="40"></td>
                    <td>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="8"/>
                                    <td className="dot"></td>
                                    <td className="fontsizesmall paddingleft">
                                        {Translate("Must contain at least 1 special character [~`!@#$%^&*()_+=|[]:;']")}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

                <tr height="20"></tr>

                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizesmall">{Translate("Password")} <span className="red"> *</span></td>
                </tr>
                   
                <tr height="10"/>

                <tr height="40">
                    <td width="40"></td>
                    <td><input type={mPasswordVisible ? "text" : "password"}  onChange={e=>SetUserPassword(e.target.value)} className="inputtd"></input>
                        <span className="passwordtoggleicon" onClick={() => SetPasswordVisible(!mPasswordVisible)}>{mPasswordVisible ? '👁️' : '👁️‍🗨️'} </span>
                    </td>
                    <td></td>
                </tr>

                <tr height="20"/>

                <tr>
                    <td width="40"></td>
                    <td width="100"><PasswordStrengthBar password={mUserPassword} /></td>
                </tr> 

                <tr height="20"/>

                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizesmall">{Translate("Confirm new password")} <span className="red"> * </span></td>
                </tr>
                   
                <tr height="10"/>

                <tr height="40">
                    <td width="40"></td>
                    <td><input type="password" onChange={e=>SetConfirmPassword(e.target.value)} className="inputtd"></input></td>
                    <td></td>
                </tr>

                <tr height="20"></tr>

                <tr>
                    <td width="40"/>
                        {mChangePasswordSuccessfully ? <td className="green bold">{mChangePasswordResult}</td> : <td className="red bold">{mChangePasswordResult}</td> }
                </tr>

                <tr height="15"></tr>
                
                <tr>
                    <td width="40"></td>
                    <td className="bold fontsizesmall">
                        <button className="saveusersettingsbutton bold" onClick={()=>handleChangePassword()}>{Translate("Change password")}</button> 
                    </td>
                </tr>
                
                <tr height="20"/>       
                </tbody>
            </table>

            {/*FreeSpace*/}
            <table>
                <tbody>
                    <tr height="80"></tr>
                </tbody>
            </table>

            {/*footerTable*/}
            <table>
                <tbody>
                    <tr height="60"></tr>
                </tbody>
            </table>

            <Footer/>

        </span>
        )
}

export default ResetPassword