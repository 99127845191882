import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode'

import Home from './Home';
import Support from "./Support";
import EditUser from './EditUser';
import Tenant from "./Tenant";
import AddNewUser from "./AddNewUser";
import User from "./User";
import TenantUsers from './TenantUsers';
import Error from './Error';
import ResetPassword from "./ResetPassword";

import './App.css';

function App() {
  //sessionStorage.setItem("token", "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblVzZXIiOiJzZG5iQGtqYmRlZi5jb20iLCJTaWduaW5QYXNzd29yZCI6IlRqQnM5RlN6c2dPMmZFWDErdkUvUkxmS053aHVKZ085ejB1N3BlVXNpbGIwS0hqSlNEL2ZQakhwZGdyRHhwRXN4MFA0SjdlWjRzTUNKOThROFYvM1FUQWx0c2FFZ3RoZFRHOHVzNjFHbXlwdUlVNnJweHZ1MDVDOXVOdy9RbnVLIiwiU2lnbmluQXBwbGljYXRpb24iOiJVc2VyUG9ydGFsIiwiVGVuYW50R3VpZCI6IlRlbmFudF8yNDFjN2MxYy1lODA0LTQxYTgtOTk4Mi1mY2UzZDc0OTE4MTQiLCJUZW5hbnROYW1lIjoiQ3JlYXIgUmF0ZSBUZXN0IENvbXBhbnkiLCJVc2VyR3VpZCI6IlRlbmFudFVzZXJfODc3NzJkNGMtZGY5OS00ODc5LThiZDYtZDM1MGI0NjkyZTJmIiwiVXNlck5hbWUiOiJGaW5hbCIsIlVzZXJJc0FkbWluIjoiMSIsIlVzZXJJc1RlbmFudEFkbWluIjoiMSIsIkVtYWlsIjoic2RuYkBramJkZWYuY29tIiwiUm9sZXMiOiJVc2VyIiwiZXhwIjoxNzIxNDY3OTcyLCJpc3MiOiJzaWduaW4tdmEiLCJhdWQiOiJDbG91ZENUSTIwMjIifQ.OMG4pemEMlEmbdLIiG1m9QoDsiXitkW0t9xmrsJCtbc")
  const token = Cookies.get("userportal");
  //const token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJTaWduaW5NZXRob2QiOiJDbG91ZENUSSIsIlNpZ25pblVzZXIiOiJzZG5iQGtqYmRlZi5jb20iLCJTaWduaW5QYXNzd29yZCI6IlRqQnM5RlN6c2dPMmZFWDErdkUvUkxmS053aHVKZ085ejB1N3BlVXNpbGIwS0hqSlNEL2ZQakhwZGdyRHhwRXN4MFA0SjdlWjRzTUNKOThROFYvM1FUQWx0c2FFZ3RoZFRHOHVzNjFHbXlwdUlVNnJweHZ1MDVDOXVOdy9RbnVLIiwiU2lnbmluQXBwbGljYXRpb24iOiJVc2VyUG9ydGFsIiwiVGVuYW50R3VpZCI6IlRlbmFudF8yNDFjN2MxYy1lODA0LTQxYTgtOTk4Mi1mY2UzZDc0OTE4MTQiLCJUZW5hbnROYW1lIjoiQ3JlYXIgUmF0ZSBUZXN0IENvbXBhbnkiLCJVc2VyR3VpZCI6IlRlbmFudFVzZXJfODc3NzJkNGMtZGY5OS00ODc5LThiZDYtZDM1MGI0NjkyZTJmIiwiVXNlck5hbWUiOiJGaW5hbCIsIlVzZXJJc0FkbWluIjoiMSIsIlVzZXJJc1RlbmFudEFkbWluIjoiMSIsIkVtYWlsIjoic2RuYkBramJkZWYuY29tIiwiUm9sZXMiOiJVc2VyIiwiZXhwIjoxNzIxNDY3OTcyLCJpc3MiOiJzaWduaW4tdmEiLCJhdWQiOiJDbG91ZENUSTIwMjIifQ.OMG4pemEMlEmbdLIiG1m9QoDsiXitkW0t9xmrsJCtbc"
  //const token = ""
  let isAuthenticated = false;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
        isAuthenticated = true;
        sessionStorage.setItem("username", decodedToken.UserName);
        sessionStorage.setItem("token", token);
        sessionStorage.setItem("userguid", decodedToken.UserGuid);
        sessionStorage.setItem("tenantguid", decodedToken.TenantGuid);
        sessionStorage.setItem("UserIsTenantAdmin", decodedToken.UserIsTenantAdmin);
      
    } catch (error) {
      console.error("Error decoding token:", error);
    }
  }

  const redirectToLogin = () => {
    const redirecturl = window.location.href.replaceAll(":", "%3A").replaceAll("/", "%2F").replaceAll("&", "%26");
    window.location.href = "https://signin-va.cloudcti.nl/signinbeta/onelogin?clientid=userportal&redirecturl=" + redirecturl;
  };

  if (!isAuthenticated && window.location.pathname !== "/resetpassword") {
    redirectToLogin();
    return null; // Avoid rendering any routes until redirection happens
  }

  return (
    <Router>
      <Routes>
        {/* Public route: accessible without authentication */}
        <Route path="/resetpassword" element={<ResetPassword />} />

        {/* Protected routes: require authentication */}
        {isAuthenticated && (
          <>
            <Route path="/home" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/support" element={<Support />} />
            <Route path="/edituser" element={<EditUser />} />
            <Route path="/tenant" element={<Tenant />} />
            <Route path="/addnewuser" element={<AddNewUser />} />
            <Route path="/user" element={<User />} />
            <Route path="/tenantusers" element={<TenantUsers />} />
            <Route path="/error" element={<Error />} />
          </>
        )}
      </Routes>
    </Router>
  );
}

export default App;
